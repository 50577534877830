.phone-input {
  width: 100%;
  padding-left: 0;
  flex-direction: row !important;

  .flag-selector {
    width: 64px !important;
    height: 100% !important;
  }

  .input {
    font-size: 16px;
    border: none;
    background: transparent;
    outline: none;
  }
}

.onboardingPhone.error {
  border: 1px solid #df6e4f;
}

.onboardingPhone {
  .flag-selector {
    height: 50px !important;
  }

  .selection-flag {
    margin-top: 4px;
  }

  input {
    background: #eff4ff !important;
    height: 50px;
    width: 100%;

    &::placeholder {
      font-family: 'Avenir';
      font-size: 14px;
    }
  }
}

.selection-flag {
  margin-left: 12px;
  line-height: 53px;
}

.flag-image {
  margin-right: 8px;
  line-height: 25px;
}

.calling-code {
  margin-left: 4px;
  font-size: 12px;
  color: $light-grey;
}

.phone-menu-item {
  div {
    div {
      div:last-child {
        display: none;
      }
    }
  }
}
