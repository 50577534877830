.read-more {
  &-button {
    color: #80a1fe;
    margin-left: 15px;
    cursor: pointer;
  }
}

.read-more-modal {
  .read-more-body {
    width: 100%;
    padding: 20px 35px;

    .message {
      font-size: 16px;
      background: $bg-grey;
      padding: 16px;
      height: 225px;
      overflow: auto;
      margin-bottom: 22px;
    }

    .full-width {
      width: 50% !important;
      margin: 15px auto 0 !important;

      button {
        background: $brand-primary-color !important;
        border: 1px solid $brand-primary-color !important;
      }
    }
  }

  .message-button-container {
    display: flex;
    justify-content: center;

    button {
      margin-right: 20px;
    }
  }
}
