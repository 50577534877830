.roomiOnboarding {
  padding: 50px;
  font-family: $Avenir;

  .roomiOnboardingCard {
    width: $onboarding-step-width;
  }

  &Step {
    padding: 10px;
    border-radius: 3px;

    &Content {
      width: 90%;
      margin: 0 auto;
    }

    .largeButton {
      font-family: $Lovelo;
      font-weight: 900;
      font-size: 20px;
      padding: 25px 50px;
    }

    h3 {
      margin-top: 2em;
    }
  }
}

@media (max-width: 600px) {
  .roomiOnboarding {
    padding: 10px;

    .roomiOnboardingCard {
      width: 100%;
    }

    &Step {
      h1 {
        font-size: 18px;
      }

      &Content {
        width: 100%;
        margin: 0 auto;
        padding: 5px;
      }
    }
  }
}

