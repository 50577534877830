.onboardingSteps {
  display: flex;
  justify-content: space-between;
  width: 150px;
  margin-top: 20px;
  margin-bottom: 20px;

  .step {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $medium-grey;
  }

  .step.completed {
    background-color: $primary-accent-color;
  }
}

@media (min-width: 600px) {
  .onboardingSteps {
    width: 200px;
  }
}
