.container {
  position: relative;
  max-width: 1100px !important;
  margin: auto !important;
}

@media (min-width: 600px) {
  .page-container {
    padding: 30px 20px;
    min-height: 100%;
  }
}

@media (max-width: 599px) {
  .page-container.mobile-padding {
    padding: 10px;
  }
}
