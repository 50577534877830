.step2 {
  .verifyImage {
    margin-top: 2em;

    img {
      width: 125px;
      height: 225px;
    }
  }

  .desc {
    margin-top: 1em;
    font-size: 14px;
  }

  .verifyField {
    margin: auto;
    margin-top: 2em;
    max-width: 250px;
  }

  .verifyTextHeader {
    margin-top: 2em;
  }

  .verifyTextDesc {
    margin-top: 8px;
  }

  .continue {
    margin: 4em 0;
  }
}
