.credit-card-selector-group {
  font-size: 14px;
  margin-bottom: 22px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .default-radio-button {
    position: relative;
    margin-right: 54px;

    &:not(:last-of-type) {
      margin-bottom: 8px;
    }

    .trash {
      position: absolute !important;
      z-index: 4;
      right: -50px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
