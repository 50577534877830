.styledInput {
  input,
  textarea {
    padding: 15px 10px;
    border: none;
    outline: none;
    background: $primary-bg-dark-color;
    font-size: 14px;
    font-family: $Avenir !important;
    width: 100%;

    &::placeholder {
      font-family: $Avenir !important;
    }
  }

  .desc {
    margin-top: 5px;
    color: $primary-dark-grey;
  }
}

textarea {
  resize: none;
}

.styledInput.error {
  input {
    background-color: $secondary-yellow;
    color: $primary-red;
    border: 1px solid rgba($primary-red, 0.5);
  }

  .errorText {
    margin: 5px 0;
    color: $primary-red;
  }
}
