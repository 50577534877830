.roomiPriceSlider {
  .rc-slider-handle {
    background: $primary-accent-color;
    border-color: $primary-accent-color;
  }

  .rc-slider-track {
    background: $primary-accent-color;
  }

  .priceToolTip {
    position: absolute;
  }

  .valuesContainer {
    position: relative;

    .min {
      position: absolute;
      left: 0;
    }

    .max {
      position: absolute;
      right: 0;
    }
  }
}
