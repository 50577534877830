.credit-card-item {
  border: 1px solid #9b9b9b;
  align-items: stretch;

  .credit-card-icon {
    border-right: 1px solid #9b9b9b;
  }

  .credit-card-number-container {
    background-color: $bg-grey;
    min-width: 180px;
    padding: 0 8px;
    color: $light-grey;
  }

  &.active {
    border-color: $brand-primary-color;

    .credit-card-icon {
      border-color: $brand-primary-color;
    }
  }
}
