button {
  border: none;
  outline: none;
}

.mediumButton {
  padding: 25px 50px;
  font-family: $Lovelo;
  font-weight: 900;
  font-size: 20px;
  color: white;
}

.smallButton {
  padding: 22px 10px;
  font-weight: 600;
  font-size: 20px;
  color: white;
}

.modalButton {
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  color: white;
}

.primaryButton {
  background-color: $primary-accent-color;

  &:hover {
    opacity: 0.8;
  }
}

.secondaryButton {
  background-color: $primary-yellow;

  &:hover {
    opacity: 0.8;
  }
}

.transparentButton.primaryButton {
  background: transparent !important;
  border: 1.5px solid $primary-accent-color;
  color: $primary-accent-color;

  &:hover {
    background: $primary-accent-color;
    color: white;
  }
}

.transparentButton.secondaryButton {
  background: transparent !important;
  border: 1.5px solid $primary-yellow;
  color: $primary-yellow;

  &:hover {
    background: $primary-yellow !important;
    color: white;
  }
}

.disabled {
  opacity: 0.4;
  pointer-events: none;
}
