@import './components/conversations-page-list';
@import './components/conversations-page-chat-area';
@import './components/conversations-page-message-header';
@import './components/conversations-page-details';
@import './components/application-banner.component';

.conversations-page {
  .conversations-page-header {
    height: 64px;
    flex-shrink: 0;
    border-bottom: 1px solid #e5e5e5;
  }

  .conversations-page-list-container {
    width: 330px;
    background: white;
    border-right: 1px solid #e5e5e5;
  }

  .conversations-page-header-title {
    transition: all 0.25s;
    font-size: 16px;
    margin-left: 10px;
    font-weight: 600;
  }

  .conversations-page-details-container {
    overflow-y: auto;
    width: 400px;
    padding: 32px;
    background: white;
    border-left: 1px solid #e5e5e5;
  }
}

@media (max-width: $screen-xs8 - 1) {
  .conversations-page {
    .conversations-page-list-container {
      width: 100%;
    }
  }
}
