.step3 {
  div {
    box-sizing: border-box;
  }

  .step3Form {
    margin-top: 2em;
  }

  .upperImages,
  .bottomImages {
    display: flex;
  }

  .biggerImage {
    position: relative;
    border: 1px solid $medium-grey;
    border-right-width: 0;
    width: 170px;
    height: 170px;

    img {
      position: absolute;
      height: 100%;
      width: 100%;
    }

    .close {
      position: absolute;
      top: 5px;
      right: 10px;

      svg {
        height: 30px;
        width: 30px;
      }
    }
  }

  .imagesGrid {
    position: relative;
    border-right: 1.5px solid $medium-grey;

    .loadingPlaceHolder {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba($medium-grey, 0.5);
    }
  }

  .smallerImage {
    position: relative;
    border: 1px solid $medium-grey;
    border-right-width: 0;
    height: 85px;
    width: 85px;
    background: white;

    .close {
      position: absolute;
      top: 0;
      right: 0;

      svg {
        height: 20px;
        width: 20px;
      }
    }

    img.beforeUpload {
      height: 40px;
      width: 40px;
    }

    img.afterUpload {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  .continue {
    margin: 4em 0;
  }
}

@media (min-width: 600px) {
  .step3 {
    .biggerImage {
      width: 300px;
      height: 300px;
    }

    .smallerImage {
      height: 150px;
      width: 150px;
      background: white;

      img.beforeUpload {
        height: 75px;
        width: 75px;
      }
    }
  }
}
