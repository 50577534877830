input[type="number"].hide-arrows::-webkit-outer-spin-button,
input[type="number"].hide-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"].hide-arrows {
  -moz-appearance: textfield;
}

.multi-select-input {
  &:not(.selected) {
    &::before {
      content: "";
      position: absolute;
      top: calc(50% - 9px);
      left: 26px;
      height: 18px;
      width: 18px;
      border: 1px solid #6d6d6d;
      border-radius: 3px;
      z-index: 1;
    }
  }

  svg {
    top: 12px !important;
    left: 23px !important;
  }
}
