.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.text-left {
  text-align: left;
}

.maintain-white-space {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.input-displays {
  outline: 0;

  &.focused,
  &:focus {
    border-color: $brand-secondary-color;
  }

  &.error {
    border-color: #fdcccc;
  }
}

.light-grey {
  color: $light-grey;
}
