.settings-page {
  background-color: $bg-grey;

  .settings-selection-container {
    background: white;
    max-width: 500px;
    margin: auto;
  }

  .notifications-header {
    text-transform: uppercase;
  }

  h1 {
    padding-left: 50px;
    padding-top: 24px;
    margin-top: 0;
  }

  .settings-section {
    border-bottom: 1px solid $bg-grey;
    padding: 0 32px;
  }

  .sticky {
    position: sticky;
    top: 0;
    padding-top: 12px;
    z-index: 2;
    background: white;

    .section {
      margin: 0 0 18px;
    }
  }

  h3 {
    color: #999;
    font-size: 12px;
    width: 100%;
    height: 30px;
    margin: 0;
    text-transform: uppercase;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    margin: 0;

    &.form-label {
      min-width: 130px;
      width: 130px;
    }
  }

  .submit-button {
    width: 50%;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
  }

  .settings-notifications {
    .layout-row {
      padding-bottom: 16px;
    }

    svg {
      margin-right: 5px;

      path {
        fill: $light-grey;
      }
    }
  }

  .standard-input {
    background: $bg-grey;
    height: 40px;
  }

  .settings-payment {
    .settings-default {
      font-size: 8px;
    }

    .add-credit-card-button {
      margin-left: 3px;
      margin-top: 10px;
    }

    .section {
      margin-top: 18px;
    }
  }

  .settings-payout {
    .settings-default {
      font-size: 8px;
    }

    .add-credit-card-button {
      margin-left: 3px;
      margin-top: 10px;
    }
  }

  .profile-viewable-control {
    width: 100%;
  }

  .profile-viewable-label {
    font-weight: 700;
    font-size: 14px;
    flex: 1;
  }
}

@media (max-width: 600px) {
  .settings-page {
    .settings-personal {
      .layout-row {
        flex-direction: column;
      }

      .align-center {
        align-items: flex-start;
      }

      .form-label {
        margin-bottom: 8px;
      }
    }

    h1 {
      padding-left: 34px;
    }

    .settings-section {
      padding: 0 16px;
    }

    .settings-selection-container {
      max-width: inherit;
    }
  }
}
