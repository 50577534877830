.message-typing-indicator {
  transition: max-height 0.5s;
  max-height: 0;
  height: 30px;
  margin-left: 56px;
  color: $light-grey;

  &.active {
    max-height: 30px;
  }
}
