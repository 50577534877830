.credit-card-selector-add {
  color: #9b9b9b;
  transition: all 0.25s;
  width: 278px;
  cursor: pointer;
  height: 40px;

  path {
    fill: #9b9b9b;
  }

  &:hover {
    color: $brand-primary-color;

    path {
      transition: fill 0.25s;
      fill: $brand-primary-color;
    }
  }
}
