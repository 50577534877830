.my-import-card {
  background: white;
  height: 200px;
  align-items: center;
  padding: 32px;

  .title {
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 8px;

    a:hover {
      transition: 0.25s all;
      color: #80a1fe;
    }
  }

  .status {
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px;
    color: #d6d6d6;

    &.Pending {
      color: $primary-yellow;
    }

    &.Rejected {
      color: $primary-red;
    }

    &.Fulfilled {
      color: $linkedIn;
    }
  }
}

@media (max-width: $screen-xs8) {
  .my-import-card {
    padding: 16px;

    .title {
      font-size: 20px;
    }
  }
}
