.application-banner {
  margin: 10px;
  padding: 12px;
  background: #fcf6e3;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);

  .application-button {
    margin-left: 8px;
    min-width: 180px !important;
  }

  svg {
    margin-right: 10px;
  }
}

@media (max-width: $screen-sm - 1) {
  .application-banner {
    flex-direction: column;
    align-items: start;

    & > div {
      flex: 0 0 auto !important;
    }

    .application-button {
      width: 100% !important;
      margin-left: 0;
      margin-top: 5px;
      min-width: initial !important;
    }
  }
}
