.booking-page-message {
  .default-text-area-container {
    background: white;
    min-height: 200px;
  }

  .booking-message-placeholder {
    ul {
      margin-bottom: 0;
      padding-left: 14px;

      li:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}
