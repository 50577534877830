.noMargin {
  margin: 0 !important;
}

.noPadding {
  padding: 0 !important;
}

.full-width {
  width: 100%;
}

.absolute-fill-parent {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pos-relative {
  position: relative;
}

.inline {
  display: inline-block;
}

.overflow {
  overflow: auto;
}

.flex {
  max-width: 100%;
  flex: 1 1 0% !important;
  min-width: 0;
  min-height: 0;
}

.flex-5 {
  flex: 1 0 5%;
  max-width: 5%;
  width: 5%;
}

.flex-20 {
  flex: 1 0 20%;
  max-width: 20%;
  width: 20%;
}

.flex-25 {
  flex: 1 0 25%;
  max-width: 25%;
  width: 25%;
}

.flex-30 {
  flex: 1 0 30%;
  max-width: 30%;
  width: 30%;
}

.flex-40 {
  flex: 1 0 40%;
  max-width: 40%;
  width: 40%;
}

.flex-45 {
  flex: 1 0 45%;
  max-width: 45%;
  width: 45%;
}

.flex-50 {
  flex: 1 0 50%;
  max-width: 50%;
  width: 50%;
}

.flex-60 {
  flex: 1 0 60%;
  max-width: 60%;
  width: 60%;
}

.flex-70 {
  flex: 1 0 70%;
  max-width: 70%;
  width: 70%;
}

.flex-none {
  flex: 0 0 auto !important;
}

.flex-auto {
  min-width: 0;
  max-width: 100%;
  flex: 1 1 auto !important;
}

.flex-nogrow {
  min-width: 0;
  max-width: 100%;
  flex: 0 1 auto !important;
  min-height: 0;
}

.flex-noshrink {
  flex: 1 0 auto !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-center {
  align-items: center;
  justify-content: center;
  display: flex;
}

.flex-start {
  align-items: start;
}

.layout-column {
  display: flex;
  flex-direction: column;
}

.layout-align-around {
  justify-content: space-around;
}

.layout-align-between {
  justify-content: space-between;
}

.align-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.layout-stretch {
  align-items: stretch;
}

.layout-align-center {
  align-items: center;
}

.layout-align-end {
  align-items: flex-end;
}

.layout-row {
  display: flex;
  flex-direction: row;
}

.layout-wrap {
  flex-wrap: wrap;
}

.layout-space-between {
  justify-content: space-between;
  align-items: center;
}

.layout-padding,
.layout-padding > * {
  padding: 8px;
}

.layout-padding-sm,
.layout-padding-sm > * {
  padding: 4px;
}

.align-center {
  align-content: center;
  align-items: center;
}

.fill-parent {
  height: 100%;
  width: 100%;
}

.container-sm {
  max-width: 960px !important;

  &.mobile-padding {
    padding: 30px 20px;
  }
}

.grid-sm {
  @extend .container-sm;

  margin: 0 auto;
}

.grid-md {
  max-width: $screen-md;
  margin: 0 auto;
}

@media (max-width: $screen-xs8) {
  .layout-xs-column {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: $screen-xs8) {
  .hide-gt-xs {
    display: none !important;
  }
}

@media (max-width: $screen-xs8) {
  .hide-xs {
    display: none !important;
  }
}

@media (max-width: $screen-sm) {
  .hide-sm {
    display: none !important;
  }
}

@media (max-width: $screen-sm8 - 1) {
  .hide-sm8 {
    display: none !important;
  }
}

@media (max-width: $screen-xs8 - 1) {
  .hide-lt-xs {
    display: none !important;
  }
}

@media (min-width: $screen-sm) {
  .hide-gt-sm {
    display: none !important;
  }
}

@media (min-width: $screen-sm8) {
  .hide-gt-sm8 {
    display: none !important;
  }
}

@media (max-width: $screen-sm - 1) {
  .hide-lt-sm {
    display: none !important;
  }
}

@media (min-width: $screen-sm) {
  .hide-gt-sm {
    display: none !important;
  }
}

@include collapse-first {
  .collapse-first {
    display: none !important;
  }

  .collapsed.collapsed-first {
    display: flex !important;
  }
}

@include collapse-second {
  .collapse-second {
    display: none !important;
  }

  .collapsed.collapsed-second {
    display: flex !important;
  }
}
