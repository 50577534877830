.conversations-page-chat-area {
  background: white;
  transition: 0.25s all;

  .send-message-container {
    border-top: 1px solid $bg-grey;
  }

  .booking-invite-banner-container {
    border-bottom: 1px solid #e5e5e5;
  }
}
