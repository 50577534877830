@import './components/my-listing-card-info';
@import './components/my-listing-card-actions-container';

.my-listing-card {
  background: white;
  position: relative;
  display: flex;
  flex-direction: row;

  .listing-card-carousel-container {
    position: relative;
    height: 240px;
    max-width: 350px;
    width: 33%;

    .boost {
      svg {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 99;
      }
    }
  }

  .listing-status {
    justify-content: center;
  }

  .my-listing-card-info-container {
    padding: 40px 30px;
  }

  .my-listing-card-share-container {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .my-listing-card-status {
    margin: 0;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 18px;

    &.active {
      color: $primary-green;
    }

    &.inactive,
    &.deactivated {
      color: #9b9b9b;
    }

    &.pending,
    &.incomplete {
      color: $primary-yellow;
    }

    &.rejected {
      color: #d0021b;
    }
  }
}

@media (max-width: $screen-sm) {
  .my-listing-card {
    .listing-card-carousel-container {
      height: 200px;
    }

    .my-listing-card-info-container {
      padding: 16px 8px;
    }
  }
}

@media (max-width: $screen-xs8) {
  .my-listing-card {
    display: block;

    .listing-card-carousel-container {
      width: 100%;
      padding-bottom: 76%;
      max-width: initial;
    }

    .my-listing-card-info-container {
      padding: 0;
    }
  }
}
