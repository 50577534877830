@import './components/listing-details-carousel/listing-details-carousel.scss';
@import './components/listing-details-information/listing-details-information';
@import './components/listing-details-profile/listing-details-profile';
@import './components/weekly-listing-views/weekly-listing-views.scss';
@import './components/listing-details-loading/listing-details-loading';
@import './components/listing-details-status-banner/listing-details-status-banner';
@import './components/listing-details-inactive-banner/listing-details-inactive-banner';

.zIndexHelper {
  z-index: 1100;
}

.buttonStickyContainer {
  button,
  a {
    width: 100%;
  }
}

.detailSection {
  width: 100%;
  margin: auto;
  padding: 0 15px;
  margin-top: 1em;
  font-family: Avenir !important;
  display: flex;
  flex-direction: column;

  .listingDetailSection {
    width: 100%;
    //background: red;
  }

  .userDetailSection {
    width: 100%;
    margin-top: 0.75em;
  }
}

.flag-trigger {
  color: $primary-color;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  font-weight: 700;

  &:hover {
    color: darken($primary-color, 10%);

    svg path {
      stroke: darken($primary-color, 10%);
    }
  }

  &.flagged {
    opacity: 0.7;
    cursor: default;
    pointer-events: none;

    svg path {
      fill: $primary-color;
    }
  }

  svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;
  }
}

.detailSection-placeHolder {
  width: 100%;
  margin: auto;
  padding: 0 15px;
  margin-top: 3em;
  display: flex;
  flex-direction: column;

  .listingDetailSection-placeHolder {
    width: 100%;
    //background: red;
  }

  .userDetailSection-placeHolder {
    width: 100%;
    margin-top: 0.75em;
  }
}

.flagListing {
  margin-top: 1em;
  margin-left: 1.75em;
}

.listing-details {
  z-index: 1;
  background: white;
  position: relative;

  &.is-disabled {
    opacity: 0.6;
    filter: grayscale(100%);
    pointer-events: none;
  }

  .listing-details-carousel-container {
    position: relative;
    max-height: 550px;
    min-height: 300px;
    height: 70vh;
  }

  .listing-middle-container {
    padding: 20px 0 40px;
    position: relative;
  }

  .basic-info-container {
    background: white;
  }

  .listing-details-profile-placeholder {
    position: relative;
    width: 380px;
  }

  .listing-details-profile-container {
    position: relative;
    height: calc(100% + 200px);
    width: 100%;
  }

  .transportation {
    height: 400px;
  }

  .listing-details-information-container {
    padding-right: 30px;
  }
}

.listing-details-suggested-container {
  position: relative;
  width: 100%;
  z-index: 1;

  &::before {
    background: $secondary-yellow;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    top: 0;
    right: 0;
    transform: skewY(1.5deg);
    transform-origin: 100% 0;
    position: absolute;
    -webkit-backface-visibility: hidden; // for Chrome Windows
  }
}

.listing-details-suggested-container {
  background: $secondary-yellow;
  padding: 25px;
}

@media (min-width: 960px) {
  .listingSection {
    padding: 0 3% 60px;
  }

  .detailSection {
    margin-top: 2.5em;
    flex-direction: row;

    .listingDetailSection {
      width: 70%;
      padding-right: 4%;
    }

    .userDetailSection {
      width: 30%;
      margin-top: 0;
    }
  }

  .listingSection-placeHolder {
    padding: 0 3% 60px;
  }

  .detailSection-placeHolder {
    flex-direction: row;

    .listingDetailSection-placeHolder {
      width: 70% !important;
    }

    .userDetailSection-placeHolder {
      width: 30%;
      padding: 10px;
      margin-top: 0;
    }
  }
}

@media (max-width: 959px) {
  .listing-details-size-container {
    padding: 0;
  }

  .listing-details {
    .listing-middle-container {
      padding: 20px 0;
    }

    .listing-details-information-container {
      padding: 0;
    }

    .listing-details-carousel-container {
      height: calc(100vh - 359px);
    }
  }

  .listing-details-suggested-container {
    padding: 20px;
  }

  .listing-details-mobile-buttons {
    position: realtive;
    z-index: 1400;
  }
}

@media (min-width: 599px) {
  .listing-details-inactive-banner-container {
    .listing-details-size-container {
      padding: 0 40px;
    }

    .listing-details-suggested-container {
      padding: 20px 0;
    }
  }
}

@media (max-width: 480px) {
  .flag-trigger {
    justify-content: center;
  }
}
