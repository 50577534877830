$dashboard-starts-acting-like-ipad: 768px;

.testimonials {
  background: $bg-grey;

  .heading-container {
    padding: 32px 0 120px;
    background: linear-gradient(32deg, $primary-bg-accent-dark-color 0%, $primary-bg-accent-dark-color 100%);

    .pageContainer {
      max-width: 980px !important;
      padding: 0 40px;
      align-content: center;
      align-items: center;
      margin: 0 auto;
    }

    .testimonial-header {
      max-width: 440px;
      display: inline-block;
      color: white;

      .title {
        font-size: 40px;
        font-weight: 400;
        margin-bottom: 20px;
      }

      .divider {
        display: block;
        margin-top: 20px;
        width: 186px;
        border-top-style: solid;
        border-width: 3px;
        border-color: $primary-bg-light-color;
      }
    }

    img {
      max-width: 100%;
    }
  }
}

.pageContainer {
  margin: 0 auto;
  width: 100%;
  max-width: 980px !important;

  .testimonial-list-container {
    margin-top: -110px;
    padding: 0 20px;
    justify-content: space-between;

    .testimonial-container {
      background: white;
      padding: 32px;
      box-sizing: border-box;
      border-radius: 10px;
      font-size: 16px;
      color: $light-grey;
      max-width: 500px;

      &.active {
        box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);

        .quote-text {
          max-height: 550px;
        }

        path {
          fill-opacity: 1;
          fill: $primary-bg-accent-dark-color;
        }
      }
    }
  }

  .quote-name {
    color: $primary-bg-accent-dark-color;
  }
}

@media (min-width: $dashboard-starts-acting-like-ipad) {
  .testimonials {
    .heading-container {
      .pageContainer {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}

