@import "./fullscreen-carousel/fullscreen-carousel.component.scss";

.listing-details-carousel {
  position: relative;

  .slick-track,
  .slick-list,
  .carousel-image-container {
    max-height: 100%;
    min-height: 300px;
    height: 70vh;
  }

  .carousel-image-container {
    margin: 0 !important;

    img {
      height: 100%;
      width: auto;
      margin: 0 auto;
      pointer-events: none;
    }
  }

  .badges-container {
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media (max-width: 599px) {
  .listing-details-carousel {
    .carousel-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
}
