.conversations-page-list {
  .conversations-page-list-header {
    padding: 0 24px;
  }

  .conversation-list-container {
    position: relative;
    overflow: auto;

    .conversation-nav-link {
      .conversation-list-item {
        border-bottom: 1px solid #dedede !important;

        &.active,
        &:hover {
          background: #efefef !important;
        }
      }
    }
  }

  .new-message-placeholder {
    height: 55px;
    border-top: 1px solid #e5e5e5;
  }
}
