.radioButton {
  svg {
    height: 20px;
    width: 20px;
  }
}

.selected {
  path {
    fill: $primary-accent-color !important;
  }

  label {
    color: $primary-accent-color !important;
  }
}
