.fullscreen-listing-details-carousel {
  padding: 24px 0;
  height: 100%;

  .image-gallery-slide-wrapper {
    max-height: calc(100vh - 160px);
    min-height: calc(100vh - 250px);
    margin-bottom: 40px;

    svg {
      height: 50px;
      width: 50px;
      padding: 0;
    }

    .image-gallery-index {
      bottom: -40px;
      top: inherit;
      right: calc(50% - 36px);
    }

    .image-gallery-swipe {
      height: calc(100vh - 160px);

      .image-gallery-slides {
        height: calc(100vh - 160px);

        .image-gallery-slide {
          background: transparent;
          max-height: calc(100vh - 160px);
          min-height: calc(100vh - 250px);

          .image-gallery-image {
            max-height: calc(100vh - 160px);
            min-height: calc(100vh - 250px);
            display: flex;
            flex-direction: row;
            justify-content: center;

            img {
              max-height: calc(100vh - 160px);
              display: block;
              width: auto;
              max-width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }

  .close {
    color: white;
    font-weight: 500;
    margin: 0 8px;

    &:hover {
      cursor: pointer;
    }
  }

  .image-gallery-thumbnails-wrapper {
    max-height: 70px;

    .image-gallery-thumbnails {
      max-height: 70px;

      .image-gallery-thumbnails-container {
        max-height: 70px;

        .image-gallery-thumbnail {
          max-height: 70px;

          &.active {
            border: 4px solid $primary-accent-color;
          }

          div {
            max-height: 70px;

            img {
              max-height: 53px;
              min-height: 53px;
              display: block;
              width: auto;
              height: auto;
              margin: auto;
              max-width: 92px;
            }
          }
        }
      }
    }
  }
}

.modal-bg-dimmed {
  background-color: rgba(0, 0, 0, 0.82) !important;
}

.fullscreen-modal-content {
  height: 100vh;
  transform: inherit !important;
  width: inherit !important;
  max-width: inherit !important;

  .fullscreen-modal-paper {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: transparent !important;
    box-shadow: none !important;

    .fullscreen-modal-body {
      width: 90%;
      padding: 0 !important;
      max-height: none !important;
      overflow-y: unset !important;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
  }

  @media only screen and (max-width: $screen-sm - 1) {
    .fullscreen-modal-body {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .image-gallery-thumbnails-wrapper {
      max-height: 50px;

      .image-gallery-thumbnail {
        border: 2px solid transparent !important;

        div {
          img {
            max-width: 71px !important;
          }
        }

        &.active {
          border: 2px solid $primary-accent-color !important;
        }
      }
    }
  }
}

