.my-listing-card-actions-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;

  svg {
    cursor: pointer;
  }
}

@media (max-width: $screen-xs8) {
  .my-listing-card-actions-container {
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (max-width: $screen-sm) and (min-width: $screen-xs8 - 1) {
  .my-listing-card-actions-container {
    width: 180px;
    flex-direction: column;
  }
}
