.booking-page-how-it-works {
  .booking-page-how-answers {
    padding-left: 0;
    margin: 0;
    color: $light-grey;

    > li {
      margin-bottom: 10px;
    }

    .dashed {
      margin-bottom: 10px;
    }
  }
}
