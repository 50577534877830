.booking-page-image-card {
  width: 100%;
  overflow: hidden;

  .booking-page-image-container {
    background: white;
    position: relative;

    .booking-page-image-card-avatar-container {
      position: absolute;
      overflow: hidden;
      bottom: -60px;
      right: 18px;
      border: 3px solid white;
      background-color: white;
      border-radius: 50%;
    }
  }

  .booking-page-image-card-info {
    background: white;
    padding: 12px 16px;

    .booking-page-image-card-info-name {
      margin-top: 8px;
      font-size: 24px;
      color: $dark-grey;
      margin-bottom: 4px;
      font-weight: 500;
    }

    .booking-page-image-card-info-location {
      font-size: 14px;
      margin: 0;
      color: $light-grey;
    }
  }
}

@media (max-width: 959px) {
  .booking-page-image-card {
    .booking-page-image-container {
      height: 40vh;
      min-height: 200px;
      max-height: 340px;
    }
  }
}
