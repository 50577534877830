.user-image {
  position: relative;

  .background-status-badge {
    position: absolute;
    top: -1px;
    left: 8px;
    height: 60px;
    width: 50px;
  }

  .image {
    background-color: $bg-grey;
  }
}

@media (max-width: $screen-sm) {
  .user-image {
    .background-status-badge {
      height: 26px;
      width: 21px;
    }
  }
}
