ul.dashed {
  list-style: none;

  & > li::before {
    display: inline-block;
    content: "-";
    width: 8px;
    margin-left: -1em;
  }
}

ol.numbers {
  counter-reset: list;

  > li {
    position: relative;
    list-style: none;
    margin-left: 16px;

    &::before {
      position: absolute;
      left: -16px;
      content: counter(list, decimal) ") ";
      counter-increment: list;
    }
  }
}
