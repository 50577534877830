@import './components/booking-page-image-card';
@import './components/booking-page-movein-details';
@import './components/booking-page-how-it-works';
@import './components/booking-page-message';

.booking-page {
  background: white;
  font-size: 14px;

  .error-banner {
    margin-bottom: 8px;
  }

  .booking-page-preview-container {
    margin-right: 16px;
    width: 360px;
  }

  .credit-card-selector-title {
    margin-bottom: 16px;
    font-weight: 400;
    color: $dark-grey;
  }

  .add-credit-card-button {
    margin-left: 3px;
    margin-top: 10px;
  }

  .change-payment-option-btn {
    margin: 8px 8px 8px 0;
    height: 40px;
  }

  .booking-page-image-card-container {
    margin-bottom: 16px;
  }

  .section-header {
    padding: 0 16px;
    background: white;
    border-bottom: 1px solid $bg-grey;
  }

  .booking-page-content-container {
    padding: 24px 17px;
    background: white;
    color: $dark-grey;
  }

  .booking-page-section-container {
    margin-bottom: 8px;
    border: 1px solid $primary-light-grey;
  }

  .booking-page-banner {
    .section-title {
      margin-bottom: 8px;
      font-size: 22px;
      font-weight: 700;
    }
  }

  .disclaimer {
    font-size: 14px;
    padding: 10px 0;
    width: 100%;
    margin: 0;
  }

  .booking-page-submit-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

@media (max-width: 959px) {
  .booking-page {
    .page-container {
      padding: 0;
    }

    .booking-page-preview-container {
      width: 100%;
    }

    .booking-page-detailed-info-container {
      margin: 24px;
    }
  }
}

@media (max-width: 599px) {
  .booking-page {
    .booking-page-detailed-info-container {
      margin: 8px;
    }

    .booking-page-submit-section {
      align-items: stretch;
      background: $bg-grey;

      .disclaimer {
        max-width: 340px;
        margin: auto;
        text-align: center;
        padding: 10px;
      }
    }

    .booking-page-content-container {
      padding: 16px;
    }
  }
}
