.generalTextStyles {
  font-family: $Avenir;
  font-weight: Heavy;
  color: $black;
}

.largeText {
  font-size: 25px;
}

.medium {
  font-size: 20px;
  font-family: $Lovelo;
}

.small20 {
  font-size: 20px;
}

.small18 {
  font-size: 18px !important;
}

.small16 {
  font-size: 16px;
}

.small14 {
  font-size: 14px;
}

.small14Grey {
  font-size: 14px;
  color: $dark-grey;
}

.XSmall {
  font-size: 12px;
}

.error {
  font-size: 14px;
  font-weight: Book;
  color: $primary-red;
}

.success {
  font-size: 14px;
  font-weight: Medium;
  color: $primary-green;
}

// Mobile First
@media (min-width: 600px) {
  .medium {
    font-size: 25px;
  }

  .largeText {
    font-size: 35px;
  }
}
