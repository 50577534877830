.conversation-details {
  text-align: center;

  .conversation-details-user-title {
    font-size: 18px;
  }

  .conversation-details-user-info {
    padding-bottom: 32px;

    &.border {
      border-bottom: 1px dashed #9b9b9b;
    }
  }

  .conversation-details-listing-container {
    padding: 16px;
    background: white;
    text-align: initial;
  }

  .conversation-details-listing-info {
    .title {
      font-size: 24px;
    }
  }
}
