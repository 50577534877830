.conversations-page-message-header {
  padding: 0 24px;

  .conversations-page-message-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &:not(.inactive):hover {
      h2 {
        color: rgb(128, 161, 254);
      }
    }
  }
}
