@import 'components/manage-listings-my-listings';

.manage-listings {
  background: $bg-grey;

  .my-listings-card-container {
    margin-bottom: 10px;
  }

  .manage-listings-header {
    background: white;
    padding: 0 24px;
    margin-bottom: 1px;
  }

  .manage-listings-tabs {
    > div:nth-child(2) {
      margin-bottom: 10px;
    }
  }

  .import-listing-add-icon {
    font-size: 16px;
    margin-bottom: 1px;
  }
}
