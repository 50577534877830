.step4 {
  .linkedInConnectText {
    margin-top: 1em;
  }

  .jobFields {
    max-width: 250px;
    margin: auto;
    margin-top: 2em;

    .fieldDesc {
      margin-top: 2em;
      margin-bottom: 0.75em;
    }
  }

  .continue {
    margin: 4em 0;
  }

  .linkedInOnboarding {
    color: white;
    border-radius: 3px;
  }

  .companyBlock {
    margin-top: 3em;
    position: relative;
    border: 1px solid $primary-light-grey;
    padding: 25px 175px 25px 50px;
    max-width: 100%;
    white-space: nowrap;

    h3 {
      margin-top: 0 !important;
    }

    .status {
      margin-top: 1em;
    }

    .remove {
      position: absolute;
      top: -10px;
      right: -10px;
    }
  }
}

