.outgoing-application-card {
  border-top: 10px solid $bg-grey;

  .card-body {
    padding: 15px;
  }

  .error-container {
    padding: 20px 16px !important;

    p {
      font-size: 15px;
    }
  }

  .listing-image-wrapper {
    background-color: $bg-grey;
    margin-right: 15px;
    width: 265px;

    .room-rent {
      position: absolute;

      & > span {
        padding: 4px;
        background-color: rgba(0, 0, 0, 0.64);
        position: relative;
        top: -45px;
        color: white;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .subtitle {
    color: #9b9b9b;
    text-transform: uppercase;
    font-weight: 500;
  }

  .metadata {
    position: relative;

    &:not(.layout-align-between) {
      & > div:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    .listing-location {
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;

      @include hoverer(color, black, $primary-accent-color);
    }

    .dates {
      svg {
        margin-right: 10px;
      }

      .icon-data {
        font-weight: 500;
        margin-right: 20px;
      }
    }
  }

  .cancel-button {
    align-self: flex-start;
  }

  .expiration-container {
    margin-right: 10px;
  }
}

@media (max-width: $screen-sm) {
  .outgoing-application-card {
    .listing-image-wrapper {
      width: 132px;
    }
  }
}
