.section-header {
  padding: 0 20px;
  min-height: 54px;
  height: 54px;

  .section-title {
    font-weight: 500;
    margin: 0;
  }

  .section-header-icon {
    margin-right: 15px;
    height: 24px;
    width: 24px;
  }
}
