a {
  text-decoration: none;
  color: inherit;
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

svg {
  overflow: hidden;
}

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}

body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  color: #333;
  height: 100%;
  width: 100%;
  max-width: 100%;
  margin: 0;
  font-size: 14px;
  font-family: 'Avenir', "Roboto", "Helvetica Neue", sans-serif;

  &.branch-banner-is-active > div#react-root {
    margin-top: 78px !important;
  }
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

.clickable {
  cursor: pointer;
}

.gm-style-iw {
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  min-width: 200px;

  & > div {
    width: 100% !important;

    & > div > div { // google map window fix
      padding-bottom: 12px;
      border-bottom: 1px solid transparent;
    }
  }

  & + div {
    display: none;
  }

  .listing-card {
    max-width: 340px;
    overflow: hidden;
    padding-bottom: 5px;
  }
}

.round-image {
  border-radius: 50%;
  overflow: hidden;
}

.main-body-container {
  position: relative;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;

  > *:first-child {
    flex: 1 0 auto;
  }

  &.full-screen {
    flex: 1 1 auto;
    min-height: 0;

    > *:first-child {
      flex: 1 1 auto;
      min-height: 0;
    }
  }
}

.text-center {
  text-align: center;
}

.collapsed {
  display: none !important;
}

.clickable {
  cursor: pointer;
}

.primary-color {
  color: $brand-primary-color;
}

.secondary-background {
  background: $brand-secondary-color;
  color: white;
}

.lightgrey-background {
  background: $bg-grey;
}

.pointer {
  cursor: pointer;
}

.white-background {
  background: white;
}

// All Button Styles should go here
%default-button {
  transition: all 0.25s !important;
  position: relative !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  cursor: pointer !important;
  margin: 0 0 10px !important;
  height: 54px !important;
  min-height: 54px !important;
  line-height: 54px !important;
  font-size: 16px !important;
  outline: none !important;
}

.badges-tooltip {
  font-size: 10px !important;
  padding: 6px 10px !important;
  opacity: 0.8 !important;
}

.break-word {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.mt20 {
  margin-top: 20px;
}

.mt40 {
  margin-top: 40px;
}

.p20 {
  padding: 20px;
}

.pt20 {
  padding-top: 20px;
}

.ptlr20 {
  padding: 20px 20px 0;
}

.mb20 {
  margin-bottom: 20px;
}

.mt20 {
  margin-top: 20px;
}

.plr30 {
  padding: 0 30px;
}

