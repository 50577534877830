.message-part {
  padding-bottom: 2px;

  .text-part {
    position: relative;
    max-width: 100%;
    padding: 10px;
    line-height: 1.5;
    display: inline-block;
    border-radius: 5px;
    background: #eff4ff;

    .message-link {
      text-decoration: underline;
    }

    &.tail::before {
      content: "\A";
      border-style: solid;
      border-width: 6px 8px 6px 0;
      border-color: transparent #eff4ff transparent transparent;
      position: absolute;
      left: -8px;
      bottom: 13px;
    }
  }

  .image-part {
    max-width: 100%;

    img {
      max-width: 100%;
    }
  }

  .location-part {
    width: 100%;
    position: relative;
    padding-bottom: 67%;
  }
}

.owner {
  .message-part {
    .text-part {
      background-color: #fcf6e3;

      &.tail::before {
        border-width: 6px 0 6px 8px;
        border-color: transparent transparent transparent #fcf6e3;
        left: initial;
        right: -8px;
      }
    }
  }
}
