.policies-navigation {
  width: 230px;

  .policies-navigation-list {
    border-radius: 0;

    .policies-navigation-section {
      cursor: pointer;
      border-left: 5px solid transparent;
      font-size: 24px;
      padding: 16px;
      margin: 0;
      font-weight: 500;
    }

    &.active {
      .policies-navigation-section {
        border-color: $primary-bg-accent-dark-color;
      }
    }

    .policies-navigation-links-container {
      margin: 0;
      padding-left: 0;
      list-style: none;
    }

    .policies-navigation-link {
      margin-bottom: 8px;

      a {
        cursor: pointer;

        &.active {
          color: $primary-bg-accent-dark-color;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
