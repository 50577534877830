.favorites-page {
  .favorites-header {
    margin-bottom: 16px;
  }

  .placeholder-container {
    min-height: 500px;
    position: relative;
    background: white;
  }

  .pagination {
    li {
      background: white;
    }
  }
}

.favorites-container {
  background: white;
  max-height: 650px;
  overflow: auto;

  .favorite-container {
    flex: 1 1 33.33%;
    width: 33.33%;
    max-width: 33.33%;
  }
}

@media (max-width: $screen-sm - 1) {
  .favorites-container {
    .favorite-container {
      flex-basis: 50%;
      width: 50%;
      max-width: 50%;
    }
  }
}

@media (max-width: $screen-xs8 - 1) {
  .favorites-container {
    max-height: 400px;

    .favorite-container {
      flex-basis: 100%;
      width: 100%;
      max-width: 100%;
    }
  }
}
