.circle-icon-container {
  .circle-icon {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    border: 1px solid white;
    background: rgba(135, 135, 135, 0.8);
    transition: 0.25s all;
  }

  &:hover {
    .circle-icon {
      border-color: $brand-primary-color;
    }
  }
}

.accented {
  .circle-icon-container {
    .circle-icon {
      background: $brand-primary-color;
      border-color: $brand-primary-color;
    }

    &:hover {
      .circle-icon {
        border-color: white;
      }
    }
  }
}

button,
a {
  &.secondary {
    @include hoverer(background-color, $brand-secondary-color, darken($brand-secondary-color, 5%));

    color: white !important;

    &[disabled] {
      background: #d1d5db !important;
    }
  }
}

button,
a {
  &.grey-btn {
    @include hoverer(background-color, white, darken(white, 5%));

    border: 1px solid #d5d5d5 !important;
    color: #9b9b9b !important;
  }

  &.secondary {
    @include hoverer(background-color, $brand-secondary-color, darken($brand-secondary-color, 5%));

    color: white !important;
    border: 1px solid $brand-secondary-color !important;

    &[disabled] {
      background: #d1d5db !important;
      border-color: #d1d5db !important;
    }
  }

  &.primary {
    @include hoverer(background-color, $brand-primary-color, darken($brand-primary-color, 5%));

    color: white !important;
    border: 1px solid $brand-primary-color !important;

    &[disabled] {
      background: #d1d5db !important;
      border-color: #d1d5db !important;
    }
  }
}

.small-button {
  border-radius: 0 !important;
  transition: all 0.25s !important;
  position: relative !important;
  max-width: 100% !important;
  height: 40px !important;
  min-height: 40px !important;
  line-height: 38px !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  > * {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  button {
    min-height: 40px !important;
    line-height: 38px !important;

    div {
      min-height: 40px !important;
      line-height: 38px !important;
    }
  }
}

.medium-button {
  transition: all 0.25s !important;
  position: relative !important;
  max-width: 100% !important;
  height: 54px !important;
  min-height: 54px !important;
  line-height: 52px !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-weight: 500 !important;

  > * {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }

  a,
  button {
    min-height: 54px !important;
    line-height: 52px !important;

    div {
      min-height: 54px !important;
      line-height: 52px !important;
    }
  }
}

.default-radio-button {
  display: inline-block !important;
  width: initial !important;

  > div {
    align-items: center;
  }
}

.bareButton {
  background: none;
  outline: none;
  border: none;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  font-style: inherit;

  &:hover {
    background: rgba($light-grey, 0.2);
  }
}

.orange-btn {
  background-color: $brand-primary-color !important;
  color: white !important;
  border: none !important;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #de5921 !important;
  }
}

.accent-btn {
  background-color: #80a1fe !important;
  color: white !important;
  border: none !important;
  transition: background-color 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: rgba(#80a1fe, 0.9) !important;
  }
}
