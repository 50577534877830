.default-text-area {
  padding: 10px;
  height: 100%;
  width: 100%;
  resize: none;
  font-size: 14px;
  border: none;
  outline: none !important;
}

.default-text-area-container {
  background: #f2f4f6;
  border-radius: 2px;
  border: 1px solid #e0e7ef;
  height: 125px;
  width: 100%;
  position: relative;
}

.default-text-area-placeholder {
  position: absolute;
  cursor: text;
  padding: 24px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: auto;
  color: rgba(0, 0, 0, 0.38);
}

textarea {
  &::-webkit-input-placeholder { color: rgba(0, 0, 0, 0.38); }
  &:-moz-placeholder { color: rgba(0, 0, 0, 0.38); }
  &::-moz-placeholder { color: rgba(0, 0, 0, 0.38); }
  &:-ms-input-placeholder { color: rgba(0, 0, 0, 0.38); }
}
