.flex-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  .flex-item {
    flex: 0 0 50%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .flex-item:first-child {
    padding-left: 0;
  }

  .flex-item:last-child {
    padding-right: 0;
  }
}

.flex-center-column {
  @extend .flex-center;

  flex-direction: column;
}

.flex-inline {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
}

.flex-inline-wrap {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;

  @media (max-width: 525px) {
    flex-direction: column;
  }
}

.flex-inline-wrap.align-top {
  align-items: start;
}

.flex-inline-wrap.align-top {
  align-items: start;
}

@media (max-width: 850px) {
  .flex-row {
    .flex-item {
      flex: 0 0 100%;
      padding: 0;
    }
  }
}
