.incoming-application-card {
  border-top: 10px solid $bg-grey;

  .pt8 {
    padding-top: 8px;
  }

  .card-body {
    padding: 15px;
  }

  .card-lower-body {
    padding: 0 15px 15px;
  }

  .error-container {
    padding: 20px 16px !important;

    p {
      font-size: 15px;
    }
  }

  .buttons {
    & > div:not(:last-child) {
      margin-right: 10px;
    }

    .full-width {
      width: inherit !important;

      button {
        color: white !important;
        background: $brand-primary-color !important;
        border: 1px solid $brand-primary-color !important;
      }
    }
  }

  .subtitle {
    color: #9b9b9b;
    text-transform: uppercase;
    font-weight: 500;
  }

  .metadata {
    position: relative;

    .user-social-buttons {
      position: absolute;
      right: -6px;
      top: -6px;
    }

    .user-name {
      font-size: 20px;
      font-weight: 700;
      cursor: pointer;

      &:hover {
        color: #80a1fe;
      }
    }

    .dates {
      svg {
        margin-right: 10px;
      }

      .icon-data {
        font-weight: 500;
        margin-right: 20px;
      }
    }
  }

  .user-image {
    margin-right: 15px;
    position: relative;

    .background-status {
      position: absolute;
      top: -1px;
      left: 8px;
      height: 60px;
      width: 50px;
    }

    .badges-container {
      position: absolute;
    }
  }

  .expiration-container {
    margin-right: 10px;
  }
}

@media (max-width: $screen-sm) {
  .incoming-application-card {
    .user-image {
      .background-status {
        height: 26px;
        width: 21px;
      }
    }

    .buttons {
      margin-top: 10px;
    }

    .layout-row.hide-gt-sm {
      margin: 10px 0 5px;
    }

    .subtitle.hide-gt-sm {
      margin: 10px 0 5px;
    }
  }
}
