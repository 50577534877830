.booking-page-move-in {
  width: 100%;
  overflow: hidden;
  background: white;
  font-size: 14px;

  .move-in-fields {
    flex-direction: row;
    display: flex;
    border-bottom: 1px solid $bg-grey;
  }

  .credit-card-selector-add {
    &:hover {
      color: $primary-accent-color;

      svg {
        path {
          fill: $primary-accent-color;
        }
      }
    }
  }

  .label-title {
    margin-bottom: 8px;
  }

  .standard-select-field,
  .default-datepicker-container {
    background: $bg-grey !important;
    padding: 0 10px !important;
  }

  .booking-page-price-container {
    padding: 0 16px 8px;

    .information-container {
      height: 54px;

      &:not(:last-of-type) {
        border-bottom: 1px solid $bg-grey;
      }
    }

    .total {
      font-size: 21px;
    }

    .message-explanation {
      font-size: 12px;
      max-width: 300px;
    }

    .help-circle {
      margin-left: 6px;
      cursor: pointer;
      outline: none;
    }

    .disclaimer {
      font-size: 12px;
      color: #b8b8b8;
    }
  }
}

@media (max-width: 599px) {
  .booking-page-move-in {
    .move-in-fields {
      display: block;
      flex-direction: column;
    }
  }
}

@media (max-width: 959px) and (min-width: 599px) {
  .booking-page-move-in {
    .move-in-fields {
      padding: 8px 46px;
    }

    .booking-page-price-container {
      padding: 0 54px 8px;
    }
  }
}
