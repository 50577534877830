.step6 {
  .lightningRoundImg {
    max-width: 150px;
    margin: auto;

    img {
      height: 200px;
      width: 150px;
    }
  }

  .step6Form {
    margin-top: 0.5em;
  }

  .questionnaireFields {
    margin-top: 1em;

    .fieldDesc {
      margin-top: 2em;
      margin-bottom: 0.75em;
    }
  }

  .ligthingRoundDesc {
    max-width: 450px;
    margin: auto;
    margin-top: 1em;
  }

  .questionnaire {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aboutQuestionnaire {
    width: 100%;
    max-width: 475px;
    text-align: center;
    margin: auto;
    margin-top: 20px;
  }

  label {
    width: 100% !important;
  }

  .continue {
    margin: 4em 0;
  }
}

@media (min-width: 480px) {
  .step6 {
    .questionnaireFields {
      margin-top: 2em;
    }
  }
}
