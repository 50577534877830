.step1 {
    position: relative;
  
    .loadingPlaceHolder {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba($medium-grey, 0.5);
      z-index: 10000;
    }
  
    .onboardingFacebook {
      text-transform: capitalize;
    }
  
    .fbDesc {
      max-width: 335px;
      margin: 10px auto 0;
      color: $primary-dark-grey;
    }
  
    &Form {
      width: 80%;
      margin: 0 auto;
    }
  
    .infoDesc {
      margin-top: 5px;
      font-size: 12px;
    }
  
    .continue {
      margin: 3em 0 4em;

      button {
        margin-left: 1em;
      }
    }
}
  
@media (max-width: 850px) {
  .step1 {
    &Form {
      width: 100%;
      padding: 0 10px;
    }
  }
}
.continue {
  margin: 2em 2em;
}
.pull-right {
  float: right
}

.step0 {
  margin: 2em 2em;
  min-width: 200px;
}

.paymentStep {
  button:nth-of-type(2) {
    margin-left: 1em;
  }
}
//   > div {
//     // > div:nth-of-type(1) {
//     //     display: none
//     // }
//     // > div:nth-of-type(2) {
//     //     display: none
//     // }
//     // > div:last-child {
//     //   > div:nth-of-type(1) {
//     //     display: none
//     //   }
//     // }
//   }
// }