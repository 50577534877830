.teamBackgroundHelper {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.member {
  &List {
    display: flex;
    flex-wrap: wrap;
    margin: -10px;
    justify-content: center;

    &Item {
      flex: 0 0 100%;
      margin: 1% 0;
      padding: 10px;
    }
  }

  &Card {
    color: white;
    font-size: 20px;
    position: relative;
    min-height: 275px;
    line-height: 1.2;

    .memberDescription {
      font-size: 14px;
      padding: 0 10px;
    }

    .titleView {
      @extend .teamBackgroundHelper;

      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 0.4s ease-out;
    }

    .descriptionView {
      @extend .teamBackgroundHelper;

      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.4s ease-in;

      div {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.3);
      }
    }

    &:hover {
      .titleView {
        transition: opacity 0.4s ease-in;
        opacity: 0;
      }

      .descriptionView {
        transition: opacity 0.4s ease-out;
        opacity: 1;
      }
    }
  }
}

@media (min-width: $screen-xs8 - 1) {
  .memberList {
    &Item {
      flex: 0 0 50%;
    }
  }
}

@media (min-width: $screen-sm - 1) {
  .memberList {
    &Item {
      flex: 0 0 33.33%;
    }
  }
}

@media (min-width: $screen-md - 1) {
  .memberList {
    &Item {
      flex: 0 0 25%;
    }
  }
}
