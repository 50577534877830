@import './components/message-group';
@import './components/message-date-group';
@import './components/message-part';
@import './components/message-typing-indicator';

.view-messages {
  overflow: auto;
  padding: 10px 0;
  position: relative;

  .view-messages-list {
    padding: 0 24px;
  }
}
