.my-listing-card-info {
  .my-listing-card-title {
    margin-top: 0;
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 500;
  }

  svg {
    margin-right: 8px;
  }
}
