.svg-white path {
  fill: white;
  transition: 0.25s;
}

.svg-primary path {
  fill: $brand-primary-color;
  transition: 0.25s;
}

.svg-secondary path {
  fill: $brand-secondary-color;
  transition: 0.25s;
}

.svg-black path {
  fill: black;
  transition: 0.25s;
}

.svg-primary-hover:hover path {
  fill: $brand-primary-color;
}

.svg-grey {
  path,
  polygon {
    fill: #979797;
  }

  polyline {
    stroke: #979797;
  }
}
