//-------------- COLORS ------------------>
$primary-bg-light-color: #f7f9ff;
$primary-bg-dark-color: #eff4ff;
$primary-accent-color: #80a1fe;
$primary-bg-accent-dark-color: #8396c8;
$primary-yellow: #dbad6f;
$secondary-yellow: #fcf6e3;
$primary-red: #df6e4f;
$primary-green: #96bda3;
$primary-light-grey:  #efefef;
$medium-grey: #d6d6d6;
$primary-dark-grey: #9b9b9b;
$darker-grey: #4a4a4a;
$black: #000;

//-------------- widths ------------------>
$onboarding-step-width: 814px;

//-------------- Fonts ------------------>
$Lovelo: 'Lovelo';
$Avenir: 'Avenir';
