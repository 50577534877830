.listing-details-status-banner {
  height: 75px;
  position: relative;
  z-index: 200;
  font-size: 14px;
  background-color: $primary-bg-dark-color;
  color: $primary-accent-color;
  padding: 0 16px;
  font-family: 'Avenir !important';

  .banner-title {
    font-size: 14px;
    margin-left: 3%;
    font-family: 'Avenir';
  }

  .reactivate-button {
    @include hoverer(color, $primary-accent-color, $primary-accent-color);

    cursor: pointer;
  }

  .edit-icon {
    @include hoverer(color, $primary-accent-color, $primary-accent-color);

    margin: 0 8px !important;
  }

  .share-button {
    color: $primary-accent-color !important;
  }

  .close-button {
    min-width: 40px !important;

    path {
      fill: white;
    }
  }
}

@media (max-width: 600px) {
  .listing-details-status-banner {
    .banner-title {
      text-align: left;
      margin-left: 0;
    }
  }
}
