.listing-details-information {
  .information-panel {
    margin-top: 2.5em;
    font-size: 14px;
    background: white;
    border: 1px solid $primary-light-grey;
    padding: 20px;

    .panel-top-header,
    .typography {
      font-size: 22px;
      font-weight: 500;
    }

    .description {
      padding-left: 10px;

      .panel-header {
        margin-top: 1em;
      }

      .panel-content {
        margin-top: 0.5em;
      }
    }

    .maps {
      .panel-content {
        padding-left: 10px;
        padding-right: 10px;
      }

      .information-panel {
        padding: 10px;
      }
    }

    .panel-header {
      &.breadcrumbs-container {
        margin-bottom: 1em;
        padding-left: 10px;
        font-size: 14px;
        color: #757575;
        font-weight: 400;

        .breadcrumbs {
          margin: 0 -8px;

          .breadCrumbArrows {
            padding-left: 5px;
            padding-right: 5px;
          }
        }
      }
    }

    .fixed-size {
      min-width: 120px;
      width: 20%;
    }

    .icon-list-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 500px;
    }

    &.list-container {
      display: flex;
      flex-direction: row;
    }

    .list-container {
      margin-top: 1em;
      padding-left: 10px;
    }
  }

  .dotContainer {
    height: 24px;
    width: 24px;

    .dot {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $primary-bg-accent-dark-color;
    }
  }

  .rulesTitle {
    margin-top: 16px;
    padding-left: 8px;
  }

  .rulesList {
    padding-left: 8px;
  }

  .info-icon-container {
    flex: 0 0 33%;
    max-width: 33%;
    display: flex;
    align-items: center;
    margin-top: 1em;

    .info-icon-text {
      margin-left: 10px;
    }
  }
}

@media (max-width: 599px) {
  .listing-details-information {
    .information-panel {
      margin-top: 0.75em;
    }

    .info-icon-container {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 20px;
    }

    .information-panel.list-container {
      flex-direction: column;

      .icon-list-container,
      .panel-header {
        flex: initial !important;
      }

      .panel-header {
        width: 100%;
        padding-bottom: 0;
      }
    }
  }
}
