/* ------- Redesign styles --------- */

// Color palette
$primary-color: #80a1fe;
$secondary-color: #efcea3;

/* ------- Redesign styles --------- */

// Width & Height
$max-width: 1120px;
$max-width-lg: 1440px;

// Color palette
$white: #fff;
$black: #000;
$orange: #ff6d1b;
$mid-grey: #999;
$dark-grey: #4a4a4a;
$light-grey: #757575;
$dark-red: #cb4300;
$green: #259c00;
$dark: #232527;
$dark-white: #a3a3a3;
$blue-facebook: #3b5998;
$validation-error: #fdcccc;

$edit-profile-bg: #575859;
$linkedIn: #368fd1;

// Brand color
$brand-primary-color: #f55e05;
$brand-secondary-color: #29bbaf;
$brand-danger-color: #d0021b;

// Typography color
$text-color: #222;
$title-color: $dark-red;
$link-color: #dfad65;
$link-hover-color: $black;

// Message color
$error-color: #f00;
$warning-color: #ffe5bb;
$success-color: $green;

// Border color
$border-color: #ededed;
$border-color-dark: #dedede;

//background-color
$bg-grey: #f2f4f6;

$font-family-primary: 'Avenir', 'Roboto', 'Helvetica Neue', sans-serif;

$font-size-headline: 30px;
$font-size-text: 12px;

$font-lighter: 300;
$font-normal: 400;
$font-lighter-bold: 500;
$font-semibold: 600;
$font-bold: 700;

$border-radius-base: 4px;
$border-radius-small: 3px;
$border-radius-large: 6px;

$base-animation-duration: 0.5s;
$fast-animation-duration: 0.2s;
$slow-animation-duration: 0.7s;
$timing-function-ease: ease;
$timing-function-ease-in-out: ease-in-out;
$timing-function-ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$timing-function-ease-out-cubic: cubic-bezier(0.215, 0.61, 0.355, 1);

$screen-xl: 1600px;
$screen-lg: 1440px;
$screen-md: 1280px;
$screen-sm: 960px;
$screen-sm8: 840px;
$screen-xs8: 600px;
$screen-xs4: 480px;

$collapse-first: 1175px;
$collapse-second: 1053px;

$footer-color-dark: $dark-white;
$footer-color-darker: #888;
