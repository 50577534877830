.message-group-container {
  width: 70%;
  padding-bottom: 10px;

  .layout-row {
    align-items: flex-end;
  }

  .message-group {
    margin-left: 16px;
  }

  &.owner {
    margin-left: auto;
    text-align: right;

    .layout-row {
      justify-content: flex-end;
    }
  }

  .message-group-time {
    text-transform: capitalize;
  }

  &:not(.owner) {
    .message-group-time {
      margin-left: 56px;
    }
  }
}
