.send-message {
  background: white;
  padding: 0 16px;

  .send-message-image-preview-container {
    position: relative;
    display: inline-block;
    margin-top: 28px;

    .remove-button {
      position: absolute !important;
      top: -24px;
      right: -24px;
    }
  }

  .send-message-textarea-container {
    padding: 13px 10px;

    .send-message-textarea {
      background: white;
      width: 100%;
      padding: 0;
      max-height: 150px;
      resize: none;
      outline: none !important;
      font-size: 16px;
      border: none;
      font-family: 'Avenir';
    }
  }

  .send-message-image-preview {
    max-height: 150px;
    min-height: 75px;
    padding-left: 12px;
  }
}
