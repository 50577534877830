.listing-image-container {
  margin: auto;
  position: relative;
  width: 171px;

  .listing-image {
    height: 228px;
    width: 171px;
    cursor: pointer;
  }

  .user-image {
    border-radius: 50%;
    position: absolute;
    bottom: 20px;
    right: -37px;
  }
}

@media (max-width: $screen-sm) {
  .listing-image-container {
    width: 75px;

    .listing-image {
      height: 100px;
      width: 75px;
    }
  }
}
