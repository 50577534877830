.listing-details.loading {
  .dark-placeholder {
    background: $bg-grey;
    height: 20px;
  }

  .dark-placeholder {
    background: #dedede;
    height: 20px;
  }

  .basic-info-container {
    .dark-placeholder {
      width: 25%;
    }

    .dark-placeholder {
      width: 50%;
      margin-bottom: 10px;
    }

    &.first {
      .dark-placeholder {
        height: 50px;
        width: 60%;
      }
    }
  }

  .description-placeholder {
    width: 100%;
    margin-top: 1em;

    .dark-placeholder {
      width: 100%;
      margin-bottom: 8px;
    }

    .first {
      .dark-placeholder {
        width: 80px;
        margin-bottom: 20px;
      }
    }

    .third {
      margin: 3em 0 1.25em;

      .dark-placeholder {
        width: 80px;
      }
    }

    .layout-row {
      max-width: 100%;

      .dark-placeholder {
        width: 80px;
      }
    }
  }

  .map-placeholder {
    margin-top: 1.25em;

    .dark-placeholder {
      width: 100%;
      height: 400px;
    }
  }

  .neighborHood {
    margin-top: 2.5em;

    .dark-placeholder {
      width: 100%;
    }
  }

  .panel-header {
    .dark-placeholder {
      width: 40%;
    }
  }

  .panel-header2 {
    .dark-placeholder {
      width: 50%;
    }
  }

  .user-avatar-container {
    .dark-placeholder {
      height: 175px;
      width: 175px;
      border-radius: 50%;
    }
  }

  .listing-details-profile-description {
    .dark-placeholder {
      margin-bottom: 8px;
      width: 100px;

      &:first-child {
        height: 40px;
        width: 200px;
      }
    }
  }

  .listing-details-profile-information {
    width: 100%;

    .dark-placeholder {
      width: 45px;
      margin-bottom: 8px;
    }

    .dark-placeholder {
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        width: 100%;
      }
    }
  }
}
