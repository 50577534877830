.weekly-listing-views {
  align-items: center;

  .panel-header {
    flex: 1 0 auto !important;

    .views-message-container {
      align-items: center;

      svg {
        margin-right: 8px;
      }
    }
  }
}

