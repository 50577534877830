.bg-status {
  margin: 5px 0 10px;
  text-transform: capitalize;

  &.success {
    color: #96bda3;
  }

  &.fail {
    color: #df6e4f;
  }

  &.pending {
    color: #cce3d4;
  }
}
