.quote-text {
  text-align: left;
  position: relative;
  top: 10px;
  bottom: 0;
  overflow: hidden;
  font-size: 18px;
  line-height: 25px;
  max-height: 75px;
  min-height: 75px;

  &::before {
    content: '...';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  p {
    min-height: 75px;
    margin-top: 0;
    margin-bottom: 10px;
    position: relative;
    padding-right: 1em;

    &::after {
      z-index: 3;
      bottom: -5px;
      content: '';
      position: absolute;
      right: 0;
      width: 1em;
      height: 1em;
      background: white;
    }
  }
}

.quotePath {
  margin: 0 20px 0 0;
  min-height: 30px;
  min-width: 30px;
  fill-opacity: 0.1;
}

.quote-name {
  text-align: right;
  color: $brand-secondary-color;
  font-weight: 600;
}

