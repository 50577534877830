@import './application-cards/incoming/incoming-application-card.component';
@import './application-cards/outgoing/outgoing-application-card';
@import './shared-components/background-status.component';
@import './shared-components/user-image.component';
@import './shared-components/listing-image';
@import './shared-components/read-more';

.my-requests {
  background-color: $bg-grey;

  .my-request-header {
    color: #4a4a4a;
    background-color: white;

    .pagination {
      background: $bg-grey;
      margin-bottom: 0;
      text-align: center;
      padding-top: 15px;

      li {
        background: white;

        &.break-me {
          background: $bg-grey;
        }

        &.active {
          background: $brand-primary-color;

          a {
            color: white;
          }
        }
      }
    }

    h2 {
      padding: 0 24px;
      font-weight: 500;
    }

    .tabs-container {
      .my-requests-tabs {
        & > div:nth-child(2) {
          max-width: 380px !important;

          div {
            background-color: $brand-primary-color !important;
          }
        }

        & > div:nth-child(1) {
          max-width: 380px !important;
        }

        .my-requests-tab {
          &[disabled] {
            opacity: 0.4;
          }
        }

        .my-requests-container {
          & > div {
            border-top: 10px solid $bg-grey;
          }
        }
      }
    }
  }

  .roomi-pagination {
    align-self: center;
  }
}

@media (min-width: $screen-xs8) {
  .my-requests {
    padding: 30px 20px;
  }
}
