@import './components/policies-navigation/policies-navigation.scss';

.policies {
  .policies-header-nav {
    text-align: center;
    border-bottom: 2px solid $bg-grey;
    padding: 16px;
    cursor: pointer;
    transition: all 0.25s;
    flex-basis: 50%;

    &.active {
      border-color: $primary-bg-accent-dark-color;
    }
  }

  .policies-info-container {
    text-align: justify;
  }

  .policies-navigation-container {
    width: 230px;
    margin-right: 20px;
    position: relative;
  }

  .policies-section {
    position: relative;
    line-height: 1.5;
  }

  .policies-section-header {
    margin-top: 0;
    color: $dark-grey;
    flex: 1;
    margin-right: 8px;
  }

  .policies-back-to-top {
    color: $primary-bg-accent-dark-color;
    cursor: pointer;
  }

  .policies-message-roomi {
    cursor: pointer;
    color: $primary-bg-accent-dark-color;
  }
}
