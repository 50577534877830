.listing-details-profile {
  text-align: center;
  padding: 10px;
  background: white;
  width: 100%;
  border: 1px solid $bg-grey;

  .user-gender-age {
    font-size: 14px;
    color: #d6d6d6;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .user-first-name {
    font-size: 35px;
    font-weight: 900;
    padding: 10px 0;
  }

  .roommate-details {
    margin-bottom: 20px;
  }

  .user-avatar-container {
    margin-bottom: 10px;

    .user-avatar-image-container {
      margin-top: 0.5em;
      height: 125px;
      width: 125px;

      .user-avatar-image {
        width: 100%;
        height: 100%;
      }
    }

    .user-avatar-name {
      margin-top: 0.5em;
      color: $primary-accent-color;
      font-size: 26px;
    }
  }

  .userBio {
    max-width: 275px;
    padding: 0 10px;
    word-break: break-word;
  }

  .learMoreAboutUser {
    padding: 2em 0 5px;
    margin: auto;
    max-width: 250px;
  }

  .userBadges {
    max-width: 300px;
    margin: auto;
  }

  .listing-details-button-container {
    display: none;
  }

  .listing-details-profile-information {
    margin-top: 1.5em;
    display: inline-block;

    .userInfo {
      display: flex;
      justify-content: center;
      margin-top: 5px;
    }
  }

  .listing-details-profile-pref {
    text-align: center;
    margin: 20px;

    p {
      margin: 0 0 10px;
    }
  }
}

@media (min-width: 959px) {
  .listing-details-profile {
    .user-avatar-container {
      .user-avatar-image-container {
        height: 175px;
        width: 175px;
      }
    }

    .listing-details-button-container {
      display: inline-block;
      margin: auto;
      margin-top: 1.75em;
    }
  }
}

