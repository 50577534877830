@mixin xs4 {
  @media only screen and (max-width: $screen-xs4) {
    @content;
  }
}

@mixin xs8 {
  @media only screen and (max-width: $screen-xs8 - 1) {
    @content;
  }
}

@mixin gtxs8 {
  @media only screen and (min-width: $screen-xs8) {
    @content;
  }
}

@mixin gtxs4 {
  @media only screen and (min-width: $screen-xs4) {
    @content;
  }
}

@mixin collapse-first {
  @media only screen and (max-width: $collapse-first) {
    @content;
  }
}

@mixin collapse-second {
  @media only screen and (max-width: $collapse-second) {
    @content;
  }
}

@mixin hoverer($property, $normal, $hovered) {
  #{$property}: $normal !important;

  &:hover {
    #{$property}: $hovered !important;
  }
}
