.step0 {
  .fieldDesc {
    margin-bottom: 1em;
  }

  .moverLister {
    margin-top: 1em;
  }

  .selectRegion {
    margin-top: 3em;
  }

  .priceSlider {
    margin-top: 4em;
  }

  .moveInDate {
    margin-top: 5em;
  }

  .continue {
    margin: 4em 0;
  }
}
