.teamPage {
  &ImageContainer {
    @extend .flex-center;

    text-align: center;
    min-height: 300px;
    height: 40vh;
    background-image: url("https://roomiweb.imgix.net/team/header.jpg");
    background-size: cover;
    background-position: center;

    h1 {
      color: white;
      font-size: 50px;
      font-weight: 300;
      letter-spacing: 1.5px;
      font-family: 'Avenir', "Roboto", "Helvetica Neue", sans-serif !important;
    }
  }

  &Content {
    @extend .grid-sm;

    padding-top: 40px;

    h1 {
      margin-top: 50px;
      margin-bottom: 30px;
      font-size: 36px;
      font-weight: 500;
      line-height: 1.1;
    }

    p {
      margin: 0 0 10px;
      font-size: 21px;
      line-height: 1.5;
    }
  }
}

