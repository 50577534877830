.listing-details-inactive {
  position: relative;
  z-index: 3;

  .banner-text {
    padding: 28px 10px;
    margin: 0 8px 0 0;
    font-weight: 600;
  }

  a {
    @include hoverer(color, white, $primary-accent-color);

    transition: 0.25s all;
  }

  img {
    height: 20px;
    width: 20px;
  }
}

@media (max-width: 600px) {
  .listing-details-inactive {
    padding: 0 20px;
  }
}
