.step5 {
  max-width: 400px;
  margin: auto;

  .step5Form {
    margin-top: 1.5em;
  }

  .describeImg {
    max-width: 150px;
    margin: auto;

    img {
      height: 200px;
      width: 175px;
    }
  }

  .describeBio {
    margin-top: 3em;
    width: 100%;

    .fieldDesc {
      margin-bottom: 0.75em;
    }
  }

  .proTip {
    padding: 15px 20px;
    text-align: justify;
    margin-top: 2em;
    background: $secondary-yellow;
    border: 0.5px solid $primary-yellow;

    &Header {
      font-weight: 500;
    }
  }

  .continue {
    margin: 4em 0;
  }
}
