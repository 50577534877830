.stepTitle {
  .title {
    margin-top: 1em;
  }

  .title.applyMoreMargin {
    margin-top: 3em;
  }
}

@media (min-width: 600px) {
  .goBack {
    margin-top: 2em;
  }
}

