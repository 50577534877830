//-------------- bg-classes ------------------>
.primary-bg-dark {
  background-color: $primary-bg-dark-color !important;
}

.primary-bg-accent {
  background-color: $primary-accent-color !important;
}

.bg-white {
  background-color: white !important;
}

.bg-primary-yellow {
  background-color: $primary-yellow;
}

.bg-secondary-yellow {
  background-color: $secondary-yellow;
}

.bg-primary-medium-grey {
  background-color: $medium-grey !important;
}

.bg-primary-green {
  background-color: $primary-green !important;
}

.bg-secondary-yellow {
  background-color: $secondary-yellow;
}

.bg-primary-medium-grey {
  background-color: $medium-grey !important;
}
//-------------- fontFamily-classes ------------------>
.lovelo {
  font-family: $Lovelo !important;
}

//-------------- fontColor-classes ------------------>
.dark-grey {
  color: $primary-dark-grey !important;
}

.light-grey {
  color: $primary-light-grey !important;
}

.medium-grey {
  color: $medium-grey !important;
}

.accent {
  color: $primary-accent-color !important;
}

.primary-yellow {
  color: $primary-yellow !important;
}

.white {
  color: white !important;
}

//---------------- Text ClassNames ------>

.upperCase {
  text-transform: uppercase;
}

.noWhiteSpace {
  white-space: nowrap;
}

.wordBreak {
  word-break: break-word;
}

//---------------- FlexStyles ClassNames ------>
.spaceBetween {
  display: flex;
  justify-content: space-between;
}

//---------------- Buttons ClassNames ------>
button {
  border: none;
  outline: none;
}

.largeButton {
  padding: 25px 10px;
  color: white;
  text-transform: uppercase;
}

.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.onboardingFacebook {
  color: white;
  padding: 8px 20px 8px 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  span {
    line-height: 18px;
  }

  @include hoverer(background-color, lighten($blue-facebook, 10%), $blue-facebook);
}

.linkedInOnboarding {
  color: white;
  padding: 10px 20px 10px 12px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-transform: capitalize;

  span {
    line-height: 18px;
  }

  @include hoverer(background-color, lighten($linkedIn, 10%), $linkedIn);
}

//---------------- hidden ClassNames ------>
.hidden-on-mobile {
  @media (max-width: 600px) {
    display: none !important;
  }
}

.hidden-on-desktop {
  @media (min-width: 600px) {
    display: none !important;
  }
}

//---------------- display ClassNames ------>
.inline-block {
  display: inline-block;
}

//---------------- Border ClassNames ------>
.border-medium-grey {
  border: 1px solid $medium-grey;
}
