.step9 {
  margin-top: 5em;

  .pendingVerificationContent {
    max-width: 500px;
    margin: auto;
  }

  .welcomeUser {
    margin-top: 1.5em;
    margin-bottom: 1em;
  }

  .welcomeText {
    max-width: 375px;
  }

  .continue {
    margin: 4em 0;
  }
}
